<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <h5 class="ion-padding" v-cloak v-if="noImages">{{ noneText }}</h5>
            <ion-item lines="full" mode="md" v-for="image of group.images" :key="image.id" @click="view(image.id)">
                <ion-icon slot="start" :src="image.data" class="icon-preview"></ion-icon>
                <ion-label>
                    <h2>{{ image.filename.split('.')[0] }}</h2>
                </ion-label>
                <DirectionIndicator class="edit-forward" :is-ltr-forward="true" />
            </ion-item>
            <ion-modal
                :is-open="isAddImageOpen"
                backdropDismiss="false"
                mode="md"
                css-class="custom-modal"
                @ionModalDidDismiss="setOpen(false)">
                <div class="modal-container">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>{{ modalTitle }}</ion-title>
                            <ion-buttons slot="end">
                                <ion-button @click="setOpen(false)"><ion-icon :icon="closeOutline"/></ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content class="ion-no-padding">
                        <ion-item lines="full" mode="md" v-for="(imageToAdd, index) in imagesToAdd" :key="index">
                            <ion-label>
                                <h2>{{ imageToAdd.filename }}</h2>
                            </ion-label>
                            <div class="icon-container" @click="remove(index)"><ion-icon :icon="closeOutline" class="edit-forward"></ion-icon></div>
                        </ion-item>
                        <div class="custom-leftright-padding">
                            <button type="button" class="custom-button button-outline file-button">
                                {{ addNewButtonText }}
                                <input
                                    type="file"
                                    @change="fileChanged"
                                    ref="imgFile"
                                    accept=".svg"
                                    multiple
                                max/>
                            </button>
                        </div>
                    </ion-content>
                    <FixedFooter :buttons="[{
                            class: 'custom-button button-clear',
                            icon: true,
                            iconPos: 'start',
                            handler: () => setOpen(false),
                            text: language.cancel
                        },
                        {
                            class: 'custom-button button-primary',
                            icon: false,
                            iconPos: '',
                            handler: () => save(),
                            text: language.saveUploads
                        }]"/>
                </div>
            </ion-modal>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">
import {IonContent, IonIcon, IonItem, IonModal, IonPage} from "@ionic/vue";
import { closeOutline } from "ionicons/icons";
import {useRoute} from "vue-router";
import {execute} from "@/mixins/LoadingMixin";
import {GET, PUT} from "@/services/HTTP";
import {computed, defineComponent, ref} from "vue";
import FixedFooter from "@/components/FixedFooter.vue";
import {Image} from "@/models/ImageModel";
import {presentValidationAlert} from "@/services/Notify";
import router from "@/router";
import {ImageGroup} from "@/models/ImageGroupModel";
import {ImageGroupId} from "@/models/ImageGroupTypes";
import {useStore} from "vuex";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default defineComponent({
    name: "Images",
    components: {
        DirectionIndicator,
        IonContent,
        IonPage,
        IonItem,
        IonIcon,
        FixedFooter,
        IonModal
    },
    async ionViewWillEnter() {
        await execute(async () => {
            this.group = await GET('/imageGroups/' + this.imageGroupId + '/images');
            this.noImages = this.group.images.length === 0;
            await this.store.dispatch('app/setPageTitle', this.group.name);
        }, this.isImage ? this.language.errors.loadingImages : this.language.errors.loadingIcons);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const route = useRoute();
        const typeId = computed(() => route.params.imageGroupTypeId as string);
        const group = ref({} as ImageGroup);
        const imagesToAdd = ref([] as Image[]);
        const imageGroupId = computed(() => route.params.imageGroupId as string);
        const isImage = computed(() => typeId.value === ImageGroupId.JourneyImages);
        const isAddImageOpen = ref(false);
        const setOpen = (state: boolean) => isAddImageOpen.value = state;
        const noImages = ref(false);
        
        const save = async () => {
            if (imagesToAdd.value.length > 0) {
                await execute(async () => {
                    await PUT('/images/' + imageGroupId.value, imagesToAdd.value);
                    imagesToAdd.value = [];
                    setOpen(false);
                    group.value = await GET('/imageGroups/' + imageGroupId.value + '/images');
                    noImages.value = group.value.images.length === 0;
                }, language.value.errors.savingFiles);
            }
        }
        
        const remove = (index: number) => {
            imagesToAdd.value.splice(index, 1);
        }
        
        const view = (id: string) => {
            router.push({name: 'image', params: {imageGroupTypeId: typeId.value, imageGroupId: imageGroupId.value, imageId: id}});
        }

        const fileChanged = (event: any) => {
            for(const file of event.target.files) {
                const filesize = ((file.size/1024)/1024) // MB
                if (filesize < 8) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        imagesToAdd.value.push({
                            filename: file.name,
                            data: reader.result as string,
                            imageGroupId: imageGroupId.value as string
                        });
                    };
                    reader.readAsDataURL(file);
                } else {
                    presentValidationAlert(file.name + '-' +  language.value.errors.maxFileSize);
                }
            }
        }
        
        return {
            group,
            imageGroupId,
            isImage,
            language,
            footerButtons: computed(() => {
                return ([{
                    class: 'custom-button button-clear',
                    icon: false,
                    iconPos: '',
                    handler: () => {
                        setOpen(true);
                    },
                    text: isImage.value ? language.value.addImages : language.value.addIcons
                }]);
            }),
            isAddImageOpen,
            setOpen,
            closeOutline,
            save,
            typeId,
            fileChanged,
            noImages,
            imagesToAdd,
            remove,
            view,
            store,
            noneText: computed(() => isImage.value ? language.value.noImagesYet : language.value.noIconsYet),
            modalTitle: computed(() => isImage.value ? language.value.uploadImages : language.value.uploadIcons),
            addNewButtonText: computed(() => isImage.value ? language.value.addNewImage : language.value.addNewIcon)
        }
    }
});
</script>

<style scoped>
</style>